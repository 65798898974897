@import '~bootstrap/dist/css/bootstrap.min.css';
@import '~react-perfect-scrollbar/dist/css/styles.css';
@import '~c3/c3.min.css';
@import '~react-draft-wysiwyg/dist/react-draft-wysiwyg.css';

@import '@kit/vendors/antd/style';
@import '@kit/vendors/bootstrap/style';
@import '@kit/vendors/perfect-scrollbar/style';
@import '@kit/vendors/nprogress/style';
@import '@kit/vendors/chartist/style';
@import '@kit/vendors/chartist-tooltip-plugin/style';
@import '@kit/vendors/jvectormap/style';
@import '@kit/vendors/react-sortable-tree/style';
@import '@kit/vendors/react-draft-wysiwyg/style';

@import '@kit/core/style'; // Clean UI KIT styles
@import '@airui/styles/style'; // Clean UI styles


/* customer css by ball */
.thumbnail {
    display: block;
    position: relative;
}
.thumbnail h2 {
    position: relative;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}
/* Container holding the image and the text */
  .container-relative, .image-container {
    position: relative;
    text-align: center;
    color: white;
  }
  
  /* Bottom left text */
  .bottom-left {
    position: absolute;
    bottom: 8px;
    left: 16px;
  }
  
  /* Top left text */
  .top-left {
    position: absolute;
    top: 8px;
    left: 16px;
  }
  
  /* Top right text */
  .top-right {
    position: absolute;
    top: 8px;
    right: 16px;
  }
  
  /* Bottom right text */
  .bottom-right {
    position: absolute;
    bottom: 8px;
    right: 16px;
  }

  /* Bottom right text */
  .bottom-center {
    position: absolute;
    bottom: 8px;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  .bottom-center2 {
    position: absolute;
    bottom: 0px;
    left: 50%;
    transform: translate(-50%, -50%);
  }
  
  /* Centered text */
  .centered {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  